import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import Section, {
  type SectionProps,
} from '@/components/base/layouts/Section/Section';

export default function BubbleSection({children, className}: SectionProps) {
  return (
    <Section
      mode="dark"
      className={twMerge(
        cn(
          'relative z-10 gap-y-0 py-xl md:py-3xl overflow-hidden',
          'rounded-t-[36px] md:rounded-t-[80px]',
          'latest-news-gradient md:latest-news-gradient-desktop',
          className,
        ),
      )}
    >
      {children}
    </Section>
  );
}
