import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import Section from '@/components/base/layouts/Section/Section';

import RecentStoryCard from './RecentStoryCard';
import {type NewsNode} from './types';

interface RecentStoryListProps {
  news: NewsNode[];
  hasBottomBorder?: boolean;
  className?: string;
}

const wrapperStyles = cva(
  'md:container bg-transparent z-10 gap-y-0 mt-0 mb-lg',
  {
    variants: {
      hasBottomBorder: {
        true: 'after:border-divider-dark after:border-b after:pb-lg after:md:pb-lg after:mx-[var(--margin)] after:md:mx-0',
      },
    },
  },
);

export default function RecentStoryList({
  news,
  hasBottomBorder,
  className,
}: RecentStoryListProps) {
  return news?.length ? (
    <Section
      mode="dark"
      topSpacing="none"
      bottomSpacing="none"
      className={twMerge(wrapperStyles({hasBottomBorder}), className)}
    >
      <div className="flex flex-nowrap overflow-auto md:w-full md:grid md:grid-cols-3 gap-x-md">
        {news.map(({node}, idx: number) => (
          <RecentStoryCard
            key={idx}
            {...node}
            className="w-10/12 md:w-full shrink-0 first:ml-[var(--margin)] last:pr-[var(--margin)] md:first:mx-0 md:last:px-0"
          />
        ))}
      </div>
    </Section>
  ) : null;
}
