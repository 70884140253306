import {useTranslations} from '@/hooks/useTranslations';
import Typography from '@/components/base/elements/Typography/Typography';
import Image from '@/components/base/elements/Image/Image';

import {categoryTagsFromTags} from '../utils/utils';

import StoryCardMeta from './StoryCardMeta';
import {type NewsCard} from './types';

interface RecentStoryCardProps extends NewsCard {
  className: string;
}

export default function RecentStoryCard({
  handle,
  image,
  publishedAt,
  tags,
  title,
  className = '',
}: RecentStoryCardProps) {
  const {localizePath} = useTranslations();

  return (
    <article className={className}>
      <a href={localizePath(`/news/${handle}`)} className="block group">
        <StoryCardMeta
          date={publishedAt}
          tags={categoryTagsFromTags(tags)}
          className="hidden md:flex"
        />
        {image && (
          <Image
            src={image.src}
            srcSet={`${image.src}&width=1484&height=638 2x`}
            alt={image.altText}
            width={749}
            height={319}
            loading="lazy"
            className="mb-sm rounded-lg"
          />
        )}
        <StoryCardMeta
          date={publishedAt}
          tags={categoryTagsFromTags(tags)}
          className="md:hidden"
        />
        <Typography
          as="h2"
          size="t5"
          isRichtext={false}
          className="group-hover:underline group-hover:text-link-hover"
        >
          {title}
        </Typography>
      </a>
    </article>
  );
}
