import {useEffect, useState} from 'react';
import {useFetcher} from '@remix-run/react';

import {type NewsNode} from '../components/types';

export const useLoadMore = (initialArticles: {
  edges: NewsNode[];
  pageInfo: {hasNextPage: boolean; endCursor: string | null};
}) => {
  const fetcher = useFetcher();

  const [articles, setArticles] = useState<NewsNode[]>(initialArticles.edges);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  useEffect(() => {
    setHasNextPage(initialArticles.pageInfo.hasNextPage);
  }, [initialArticles.pageInfo.hasNextPage]);

  useEffect(() => {
    setEndCursor(initialArticles.pageInfo.endCursor);
  }, [initialArticles.pageInfo.endCursor]);

  useEffect(() => {
    const fetcherData: any = fetcher.data;
    if (typeof fetcherData === 'undefined') return;

    setArticles((prevArticles) => [
      ...prevArticles,
      ...fetcherData.blogByHandle.articles.edges,
    ]);
  }, [fetcher.data]);

  useEffect(() => {
    const fetcherData: any = fetcher.data;
    if (typeof fetcherData === 'undefined') return;

    if (fetcherData.blogByHandle)
      setEndCursor(fetcherData.blogByHandle.articles.pageInfo.endCursor);
  }, [fetcher.data]);

  useEffect(() => {
    const fetcherData: any = fetcher.data;
    if (typeof fetcherData === 'undefined') return;

    if (fetcherData.blogByHandle)
      setHasNextPage(fetcherData.blogByHandle.articles.pageInfo.hasNextPage);
  }, [fetcher.data]);

  const onLoadMoreClick = async () => {
    await fetcher.submit(
      {
        endCursor,
      },
      {
        method: 'POST',
      },
    );
  };

  return {articles, hasNextPage, onLoadMoreClick};
};
