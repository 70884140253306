import {type MouseEventHandler} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import {useSharedTranslations} from '@/hooks/useTranslations';

interface LoadMoreButtonProps {
  handleClick: MouseEventHandler<HTMLButtonElement>;
  hasNextPage: boolean;
  className?: string;
}

export default function LoadMoreButton({
  handleClick,
  hasNextPage,
  className,
}: LoadMoreButtonProps) {
  const {t: tShared} = useSharedTranslations('pages/news/shared');

  return (
    <div
      className={twMerge('border-t text-center relative z-10 pt-lg', className)}
    >
      <button
        type="button"
        onClick={handleClick}
        disabled={!hasNextPage}
        className="bg-lavender-30 rounded-[30px] border border-none text-black text-t7 py-4 px-6 leading-none hover:text-white transition-colors"
      >
        {tShared('loadMore')}
      </button>
    </div>
  );
}
