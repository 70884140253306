export default function CategoryGradient() {
  return (
    <svg
      width="1440"
      height="1971"
      viewBox="0 0 1440 1971"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-0 left-0 w-full h-[calc(100%_+_var(--space-3xl))] opacity-90"
    >
      <g opacity="0.89">
        <g opacity="0.4" filter="url(#filter0_f_2785_7405)">
          <path
            d="M990.682 631.129C943.914 1138.92 460.436 1763.25 105.564 1550.56C-213.91 1359.08 218.759 1148.37 119.041 716.247C6.40771 228.157 11.505 -418.797 268.022 -75.7761C415.593 121.56 1015.74 359.11 990.682 631.129Z"
            fill="#6052DA"
          />
        </g>
        <g opacity="0.5" filter="url(#filter1_f_2785_7405)">
          <path
            d="M851.563 981.811C614.586 708.442 522.392 72.7995 796.743 -75.998C1043.73 -209.953 948.831 194.07 1215.73 328.604C1517.2 480.563 1844.6 790.541 1549.81 808.459C1380.21 818.766 978.51 1128.25 851.563 981.811Z"
            fill="#ED6BF8"
          />
        </g>
        <g opacity="0.45" filter="url(#filter2_f_2785_7405)">
          <path
            d="M339.584 1683.22C490.345 1368.74 1253.38 863.199 1698.05 890.432C2098.36 914.95 1475.37 1160.77 1536.27 1389.46C1605.06 1647.77 1486.88 2033.24 1198.01 1901.2C1031.83 1825.24 258.821 1851.69 339.584 1683.22Z"
            fill="#79DFFF"
          />
        </g>
        <g opacity="0.35" filter="url(#filter3_f_2785_7405)">
          <path
            d="M-338.797 1987.69C-207.738 1589.84 530.449 1008.53 982.816 1094.17C1390.06 1171.27 775.852 1425.76 853.319 1734.14C940.819 2082.47 847.654 2577.57 545.899 2371.14C372.304 2252.38 -409.004 2200.81 -338.797 1987.69Z"
            fill="#405EDD"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_2785_7405"
          x="-694.596"
          y="-852.537"
          width="2366.54"
          height="3126.34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="340.253"
            result="effect1_foregroundBlur_2785_7405"
          />
        </filter>
        <filter
          id="filter1_f_2785_7405"
          x="-51.9843"
          y="-783.349"
          width="2397.83"
          height="2484.94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="340.253"
            result="effect1_foregroundBlur_2785_7405"
          />
        </filter>
        <filter
          id="filter2_f_2785_7405"
          x="-346.816"
          y="208.87"
          width="2860.44"
          height="2399.63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="340.253"
            result="effect1_foregroundBlur_2785_7405"
          />
        </filter>
        <filter
          id="filter3_f_2785_7405"
          x="-1023.75"
          y="405.18"
          width="2827.77"
          height="2695.16"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="340.253"
            result="effect1_foregroundBlur_2785_7405"
          />
        </filter>
      </defs>
    </svg>
  );
}
