import {twMerge} from '@/stylesheets/twMerge';
import Section from '@/components/base/layouts/Section/Section';

import StoryCard from './StoryCard';
import {type NewsNode} from './types';

interface StoryCardListProps {
  news: NewsNode[];
  title?: string;
  className?: string;
}

export default function StoryCardList({news, className}: StoryCardListProps) {
  return news?.length ? (
    <Section
      mode="dark"
      topSpacing="none"
      bottomSpacing="none"
      className={twMerge('container bg-transparent z-10', className)}
    >
      {news.map(({node}, idx: number) => (
        <StoryCard
          key={idx}
          {...node}
          isRegularTitle
          className="border-divider-dark"
        />
      ))}
    </Section>
  ) : null;
}
